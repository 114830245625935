/* Convert to SASS Variables */
/* Buttons */
.btn-primary {
    color: #fff;
    background-color: #27509b;
    border-color: #27509b;
    border-radius: 0rem;
    padding: 0.75rem .75rem;
}
.PortalLoginLogos img {
	padding: 20px 20px;
}
/* Page Introduction */
.page-into {
  margin-top: 50px;
}
/* Form Control Overide: Styles */
.form-control {
	font-family: Arial, Helvetica, sans-serif;
	border-radius: 0rem;
	border: 1px solid #e7e8ee;
	height: calc(3rem + 2px);
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-family: Arial, Helvetica, sans-serif;
	color: #414042;
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	font-size: 14px;
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-family: Arial, Helvetica, sans-serif;
	color: #414042;
  font-size: 14px;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
	font-family: Arial, Helvetica, sans-serif;
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	color: #414042;
    font-size: 14px;
}

/* Login Box: Styles */
.loginBox {
	padding: 20px;
	color: #414042;
}
.headline {
  margin-top: 50px;
  margin-bottom:30px;
}
.headline h1 {
  font-size: 20px;
  color: #27509b;
  text-transform: uppercase;
  font-family: 'Michelin-SemiBold';
}
.headline h2 {
  font-size: 16px;
  color: #27509b;
  text-transform: uppercase;
  font-family: 'Michelin-SemiBold';
}
.loginBox a {
	color: #414042;
}
.loginBox .form-check {
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
	font-size: 16px;
}
.loginBox .accountSignUp {
	opacity: 0.5;
	filter: alpha(opacity=50); /* For IE8 and earlier */
}

/* Modal: Styles */
.modal .modal-content a.closeModal {
	cursor:pointer;
}
.modal .modal-content {
	border-radius: 0rem;
}
.signUpRegistrationForm.success {
	display: none;
}
.modal h5 {
  font-family: 'Michelin-SemiBold';
  color: #27509b;
}

/* Michelin Card with Animation */
.michelinCard {
	padding: 50px;
	border: 2px solid #e7e8ee;
  height: 270px;
}

.michelinCard {
  position: relative;
  display: inline-block;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  width: 290px;
}

.michelinCard::after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  opacity: 0;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

// .michelinCard:hover {
//   -webkit-transform: scale(1.04, 1.04);
//   transform: scale(1.04, 1.04);
// }

// .michelinCard:hover::after {
//     opacity: 1;
// }

/* The slow way */
.make-it-slow {
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

/* Transition to a bigger shadow on hover */
// .make-it-slow:hover {
//   box-shadow: 0 5px 15px rgba(0,0,0,0.3);
// }

/* The fast way */
// .make-it-fast {
//   box-shadow: 0 1px 2px rgba(0,0,0,0.15);
// }

/* Pre-render the bigger shadow, but hide it */
// .make-it-fast::after {
//   box-shadow: 0 5px 15px rgba(0,0,0,0.3);
//   opacity: 0;
//   transition: opacity 0.3s ease-in-out;
// }

/* Transition to showing the bigger shadow on hover */
// .make-it-fast:hover::after {
//   opacity: 1;
// }

@media (max-width: 1200px) {

}

@media (max-width: 992px) {

}

@media (max-width: 768px) {

}

@media (max-width: 576px) {
.PortalLoginLogos img {
	padding: 20px 20px;
}
}
